<template>
  <v-container
      id="projects"
      fluid
      align="center"
  >

    <base-material-card
        icon="mdi-bank"
        inline
        title="Billing"
    >
      <template v-if="loading">
        <v-progress-linear
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
      </template>
      <v-row v-else>
        <v-col cols="12">
          <v-card-title>
            <v-btn
                v-if="canAccess({module: 'saas', method: 'get', route: '/invoice'})"
                class="mr-2"
                dark
                small
                color="purple"
                @click="listInvoices"
            >
              <v-icon color="white">mdi-refresh</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                v-if="!apiCalled || table.data.length > 0"
                v-model="table.search"
                append-icon="mdi-magnify"
                label="Search"
            ></v-text-field>
          </v-card-title>
        </v-col>
        <v-col cols="12" v-if="!apiCalled || table.data.length > 0">
          <v-data-table
              :loading="table.data.length === 0"
              loading-text="Loading... Please wait"
              v-model="table.selected"
              :headers="table.headers"
              :items="table.data"
              :search="table.search"
              item-key="id"
              :sort-by="table.defaultSortKey"
              :sort-desc="true"
          >
            <template v-slot:item="{item}">
              <tr>
                <td>{{ new Date(item.created * 1000).toDateString() }}</td>
                <td class="text-center">
                  <v-chip :color="item.status ==='paid' ? 'success': 'error'">
                    {{ item.status }}
                  </v-chip>
                </td>
                <td>
                  {{ item.number }}
                </td>
                <td>{{ item.s_data.description }}</td>
                <td>
                  {{ new Date(item.s_data.period.start * 1000).toDateString() }} -
                  {{ new Date(item.s_data.period.end * 1000).toDateString() }}
                </td>
                <td class="text-right">
                  <vue-numeric
                      v-if="item.amount_paid > 0"
                      output_type="string"
                      class="text-right"
                      currency="$"
                      separator=","
                      :read-only="true"
                      v-bind:precision="2"
                      v-model="item.amount_paid">
                  </vue-numeric>
                  <span v-else>$ 0.00</span>
                </td>
                <td>
                  <v-icon
                      small
                      color="primary"
                      @click="newWindowInvoice(item)"
                  >
                    mdi-open-in-new
                  </v-icon>
                  <v-icon
                      color="secondary"
                      small
                      class="ml-2"
                      @click="downloadInvoice(item)"
                  >
                    mdi-cloud-download
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-else>
          <br/>
          <v-row no-gutters>
            <v-col cols="12">
              <v-alert
                  type="warning"
                  prominent
                  text
                  class="mt-5 white--text">
                <v-row
                    align="center"
                    no-gutters
                >
                  <v-col class="grow">
                    <h3>Invoices</h3>
                    <p>No invoices available at the moment, come back soon.</p>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import VueNumeric from 'vue-numeric'

const data = {
  loading: true,
  icon: 'receipt',
  label: 'Invoices',
  table: {
    search: '',
    headers: [
      {'value': 'created', 'text': 'Issued', 'sortable': true, width: '10%'},
      {'value': 'status', 'text': 'Status', 'sortable': true, width: '5%'},
      {'value': 'number', 'text': 'Number', 'sortable': true, width: '15%'},
      {'value': 'description', 'text': 'Description', 'sortable': false},
      {'value': 'period', 'text': 'Start/End Date', 'sortable': true, width: '20%'},
      {'value': 'amount', 'text': 'Amount', align: 'end', 'sortable': true, width: '8%'},
      {'value': 'actions', 'text': '', width: '7%'},
    ],
    defaultSortKey: ['created'],
    data: [],
    selected: [],
  },
  apiCalled: false
};

const methods = {

  async listInvoices() {
    const self = this;
    self.loading = true;
    self.table.data = [];
    self.apiCalled = false;
    this.clearMessages();
    self.getSendData({
      noLoading: true,
      url: '/saas/invoice',
      method: 'get',
      params: {}
    }).then(response => {
      self.loading = false;
      if (response && response.rows && Array.isArray(response.rows)) {
        response.rows.forEach((oneRow) => {
          oneRow.amount_paid = oneRow.amount_paid / 100;
        });
        self.table.data = response.rows;
      }
      self.apiCalled = true;
    }).catch(error => {
      self.loading = false;
      self.apiCalled = true;
      this.handleErrorResponse(error);
    });

  },

  newWindowInvoice(item) {
    window.open(item.hosted_invoice_url, "target=_blank")
  },

  downloadInvoice(item) {
    window.open(item.invoice_pdf, "target=_blank");
  }
};

export default {
  name: 'Billing',
  methods: methods,
  props: {},
  components: {
    VueNumeric
  },

  computed: {},

  mixins: [globalMixins],

  async created() {
    await this.listInvoices();
  },

  data: () => (data),
}
</script>
